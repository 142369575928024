<template>
  <div class="data1">
    <div class="data1_content">
      <div class="data1_title">
        <dv-decoration-3 style="width: 250px; height: 30px" />智慧茶园设备一张图<dv-decoration-3
          style="width: 250px; height: 30px" />
      </div>
      <dv-decoration-5 dur="2" style="width: 100vw; height: 20px; margin: -30px auto 30px" />
      <!-- <div class="data1_time">当前时间：{{ nowTime }}</div> -->
      <div class="data1_mainView">
        <div class="data1_left1">
          <dv-border-box-11 style="margin-top: 20px;height: 300px;" title="气象环境">
            <div class="data1_right_top1_content">
              <div class="data1_right_top1_content_t">
                <div class="data1_right_top1_item" v-for="(item, index) in imgArr1" :key="index">
                  <img src="http://qny.hyszhny.com/web/data1/data1_r_bgc.png" alt="" />
                  <div class="data1_right_top1_item_flot">
                    <div class="data1_right_top1_item_icon">
                      <img :src="item.imgUrl" alt="" />
                    </div>
                    <div class="data1_right_top1_item_number">
                      {{ item.value }}
                    </div>
                    <div class="data1_right_top1_item_text">{{ item.name }}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </dv-border-box-11>
          <dv-border-box-11  title="绿色防控">
            <div style="color: aquamarine;font-size: 17px;padding-top: 70px;padding-left: 20px;font-weight: 700;">杀虫数量统计：</div>
            <dv-capsule-chart :config="hysconfig" style="width:450px;height:280px;padding:0px 40px 10px;" />
          </dv-border-box-11>
        </div>
        <div class="data1_center">
          <div class="data1_time">当前时间：{{ nowTime }}</div>
          <dv-decoration-12
            style="width:450px;height:250px;display: flex;justify-content: center;align-items: center;margin: 0 auto;padding-top: 40px;" />
          <dv-border-box-11 title="测报灯"
            style="margin-top: 20px;width: 85%;height:40%;margin: 0 auto;padding-top: 70px;">
            <el-carousel :interval="4000" type="card" height="180px">
              <el-carousel-item v-for="item in 6" :key="item">
                <img src="http://qny.hyszhny.com/yfCBD/2967965.jpg" height="200" width="380" alt="" />
              </el-carousel-item>
            </el-carousel>
          </dv-border-box-11>
        </div>
        <div class="data1_right">
          <dv-border-box-11 title="视频监控">
            <!-- <img src="http://qny.hyszhny.com/web/data1/data1_left_top2.png" alt="" /> -->
            <div class="data1_left_top2_content">
              <div class="data1_left_top2_content_item" v-for="item, index in 5" :key="item.id">
                <img src="http://qny.hyszhny.com/web/data1/sxt.png" alt="" @click="handlerSXT(item)" />
                <!-- <div>{{ item.name }}</div> -->
                <div style="font-size: 14px;color: #f8f8f8;">{{ index + 1 }}号监控</div>
              </div>
            </div>
          </dv-border-box-11>
          <dv-border-box-11 style="margin-top: 20px;height: 300px;" title="土壤墒情">
            <div class="data1_right_top2_charts" id="data1_right_top2"></div>
          </dv-border-box-11>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
function timestampToTime(timestamp) {
  // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

export default {
  data() {
    return {
      nowTime: "",
      hysconfig: {
        data: [
          {
            name: "1号杀虫灯",
            value: 167,
          },
          {
            name: "2号杀虫灯",
            value: 67,
          },
          {
            name: "3号杀虫灯",
            value: 123,
          },
          {
            name: "4号杀虫灯",
            value: 55,
          },
          {
            name: "5号杀虫灯",
            value: 98,
          },
          {
            name: "6号杀虫灯",
            value: 167,
          },
          {
            name: "7号杀虫灯",
            value: 67,
          },
          {
            name: "8号杀虫灯",
            value: 123,
          },
          {
            name: "9号杀虫灯",
            value: 55,
          },
          {
            name: "10号杀虫灯",
            value: 98,
          },
        ],
        colors: ["#e062ae", "#fb7293", "#e690d1", "#32c5e9", "#96bfff", "#675bba", "#d14a61", "#f57575", "#f8a589", "#f9c942"]  ,
        unit: "只",
      },
      imgArr1: [
        { name: '大气温度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon1.png', value: '25度' },
        { name: '大气湿度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon2.png', value: '25度' },
        { name: '照度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon3.png', value: '25度' },
        { name: '日照时数', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon4.png', value: '25度' },
        { name: '风向', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon5.png', value: '25度' },
        { name: '风速', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon6.png', value: '25度' }
      ]
    };
  },
  created() {
    this.nowTime = timestampToTime(new Date());
    setInterval(() => {
      this.nowTime = timestampToTime(new Date());
    }, 1000);
  },
  mounted() {
    this.initCharts1();
  },
  methods: {
    initCharts1() {
      var myChart = echarts.init(document.getElementById('data1_right_top2'))
      const option = {
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '氮', '磷', '钾'],
          source: [
            { product: '2025-01-02', '氮': 43.3, '磷': 85.8, '钾': 93.7 },
            { product: '2025-01-03', '氮': 83.1, '磷': 73.4, '钾': 55.1 },
            { product: '2025-01-04', '氮': 86.4, '磷': 65.2, '钾': 82.5 },
            { product: '2025-01-05', '氮': 72.4, '磷': 53.9, '钾': 39.1 }
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option)
    },
  },
};
</script>
<style scoped lang="less">
.data1 {
  // background-image: url("http://qny.hyszhny.com/web/data1/data1_bgc.png");
  background: linear-gradient(rgba(86, 91, 159, 0.8),
      rgba(21, 21, 66, 0.2)),
    url("http://qny.hyszhny.com/web/data1/data1_bgc.png") no-repeat 0% 20% / cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data1_content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .data1_title {
    margin: 10px;
    height: 100px;
    // background: url(http://qny.hyszhny.com/web/data1/data1_title.png);
    background-size: 100% auto;
    color: #b1b3c5;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    text-align: center;
    line-height: 100px;
    display: flex;
    justify-content: center;
    align-items: last baseline;
  }

  .data1_time {
    text-align: center;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #05c6a2;
    // margin-right: 10px;
  }

  .data1_mainView {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    margin-top: 30px;

    .data1_left {
      width: 25%;
      margin-left: 30px;
      height: 70%;

      .content {
        display: flex;

        .itemcard {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          background: url(http://qny.hyszhny.com/web/data1/data1_l_bgc.png);
          background-size: 100% auto;
        }
      }
    }

    .data1_left1 {
      width: 25%;
      margin-left: 30px;
      height: 58%;

      .data1_right_top1_content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
        margin-top: 20px;

        .data1_right_top1_content_t {
          display: flex;
          flex-wrap: wrap;
          flex-shrink: 0;
          min-height: 60px;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          padding-top: 45px;

          .data1_right_top1_item {
            margin-top: 10px;
            width: 40%;
            padding: 35px;
            position: relative;

            img {
              width: 130px;
              height: 60px;
              position: absolute;
              top: 5px;
              left: 5px;
              right: 0;
              bottom: 0;
            }

            .data1_right_top1_item_flot {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              .data1_right_top1_item_icon {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 32px;
                height: 32px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .data1_right_top1_item_number {
                position: absolute;
                top: 50%;
                left: 39%;
                // transform: translate(-50%, 0);
                height: 12px;
                font-size: 14px;
                font-weight: bold;
                color: #02cda6;
              }

              .data1_right_top1_item_text {
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translate(-50%, 0);
                // width: 48px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #f8f8f8;
              }
            }
          }

          .data1_right_top1_item:nth-child(2n) {
            margin-right: 0;
          }
        }

        .data1_right_top1_content_b {
          margin-top: 40px;
          height: 100%;
          background-color: #f8f8f8;
        }
      }
    }

    .data1_center {
      width: 50%;
      height: 100%;
      background-size: 100% auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // height: 100%;
      .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
      }
    }

    .data1_right {
      width: 25%;
      margin-right: 30px;
      height: 56%;

      .data1_left_top2_content {
        position: absolute;
        top: 80px;
        left: 30px;
        right: 30px;
        bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // 隐藏滚动条
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        .data1_left_top2_content_item {
          width: 30%;
          position: relative;
          height: 106px;
          padding: 0 16px;
          // padding-top: 10px;

          img {
            cursor: pointer;
            margin: 0 auto;
            height: 100%;
          }

          div {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            font-size: 17px;
            font-weight: 400;
            color: #f0f2f5;
          }
        }
      }

      .data1_right_top2_charts {
        position: absolute;
        top: 60px;
        left: 30px;
        right: 30px;
        bottom: 15px;
        height: 250px;
        width: 96%;
      }
    }
  }
}
</style>
